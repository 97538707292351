import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptorService } from './token-interceptor.service';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FrontdashboardComponent } from './layout/frontdashboard/frontdashboard.component';
import { BackdashboardComponent } from './layout/backdashboard/backdashboard.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CookieService } from 'ngx-cookie-service';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BuyRatesComponent } from './remitz/buy-rates/buy-rates.component';
import { DestAgentBuyRatesComponent } from './remitz/dest-agent-buy-rates/dest-agent-buy-rates.component';
import { SrcAgentBuyRatesComponent } from './remitz/src-agent-buy-rates/src-agent-buy-rates.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSearchFilterModule } from 'ng-search-filter';
import { IndexModule } from './remitzfront/index/index.module';
import { AboutusComponent } from './headerpages/aboutus/aboutus.component';
import { ContactusComponent } from './headerpages/contactus/contactus.component';
import { FaqsComponent } from './headerpages/faqs/faqs.component';
import { HowitworksComponent } from './headerpages/howitworks/howitworks.component';
import { RecTransacionListComponent } from './remitz/rec-transacion-list/rec-transacion-list.component';
import { PromotionsComponent } from './remitz/promotions/promotions.component';
import { PromotionsLisComponent } from './remitz/promotions-lis/promotions-lis.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { AuthModule } from './auth/auth.module';

import { NgxUiLoaderModule } from 'ngx-ui-loader';

///
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HeaderComponent } from './components/header/header.component';



@NgModule({
  declarations: [
    AppComponent,
    FrontdashboardComponent,
    BackdashboardComponent,
    BuyRatesComponent,
    DestAgentBuyRatesComponent,
    SrcAgentBuyRatesComponent,
    AboutusComponent,
    ContactusComponent,
    FaqsComponent,
    HowitworksComponent,
    RecTransacionListComponent,
    PromotionsComponent,
    PromotionsLisComponent,
    HeaderComponent,
  
    
  ],
  imports: [
    BrowserModule,
    AutocompleteLibModule,
    AuthModule,
    AppRoutingModule,
    NoopAnimationsModule,
    FormsModule,
    MatSelectModule,
    CKEditorModule,
    MatNativeDateModule,
    NgxUiLoaderModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    HttpClientModule,
    IndexModule,
    CarouselModule,
    NgSearchFilterModule,
    NgbModule,
    NgxPaginationModule,
    NgxCaptchaModule,
  ],
  providers: [
    BnNgIdleService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
